var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('b-modal',{attrs:{"id":"modal-report","size":"xl","hide-footer":"","scrollable":""}},[_c('b-table',{staticClass:"text-center",attrs:{"items":_vm.history,"current-page":_vm.page,"hover":"","per-page":10,"fields":[{
                  key: 'agent_name',
                  label: 'Representante',
                }, {
                  key: 'date',
                  label: 'Data',
                }, {
                  key: 'type',
                  label: 'Abatimentos e Acréscimos',
                }, {
                  key: 'value',
                  label: 'Valor',
                }, {
                  key: 'note',
                  label: 'Observação'
                },
                {
                  key: 'report',
                  label: 'Relatório'
                } ]},scopedSlots:_vm._u([{key:"cell(value)",fn:function(item){return [_vm._v(" "+_vm._s(_vm._f("value")(item.item.value))+" ")]}},{key:"cell(note)",fn:function(row){return [(row.item.note)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(row.item.note),expression:"row.item.note",modifiers:{"hover":true,"top":true}}]},[_vm._v(" "+_vm._s(row.item.note.length > 31 ? ((row.item.note.substr(0, 30)) + "...") : row.item.note)+" ")]):_c('span',[_vm._v(" -------- ")])]}}])}),_c('b-pagination',{staticClass:"mt-2",attrs:{"align":"center","size":"sm","per-page":10,"total-rows":_vm.history.length},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('b-card',[_c('b-button',{staticClass:"d-block ml-auto",attrs:{"variant":"primary"},on:{"click":_vm.getHistory}},[_vm._v(" Histórico ")]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var invalid = ref.invalid;
return [_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-for":"agent","label":"Representante"}},[_c('b-form-select',{attrs:{"id":"agent","options":_vm.optionsAgents},model:{value:(_vm.agent),callback:function ($$v) {_vm.agent=$$v},expression:"agent"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-for":"month","label":"Mês"}},[_c('b-form-select',{attrs:{"id":"month","options":_vm.monthsOptions},model:{value:(_vm.month),callback:function ($$v) {_vm.month=$$v},expression:"month"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-for":"year","label":"Ano"}},[_c('b-form-select',{attrs:{"id":"year","options":_vm.optionsYears},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-for":"type","label":"Tipo"}},[_c('b-form-select',{attrs:{"id":"type","options":[{ value: null, text: 'Selecione um Tipo'},'Abatimento', 'Acréscimo']},model:{value:(_vm.data.type),callback:function ($$v) {_vm.$set(_vm.data, "type", $$v)},expression:"data.type"}})],1)],1),(_vm.data.type)?_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-for":"value","label":("Valor do " + (_vm.data.type))}},[_c('validation-provider',{attrs:{"name":("Valor do " + (_vm.data.type)),"rules":"money"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"money",rawName:"v-money",value:(_vm.money),expression:"money"}],attrs:{"id":"value","state":errors.length > 0 ? false : null},model:{value:(_vm.data.value),callback:function ($$v) {_vm.$set(_vm.data, "value", $$v)},expression:"data.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-for":"note","label":"Observações","type":"text-area"}},[_c('b-input-group',{attrs:{"append":_vm.charactersLeft}},[_c('b-form-input',{attrs:{"id":"note","state":_vm.data.note.length > _vm.noteLimit ? false : null,"placeholder":"Observações"},model:{value:(_vm.data.note),callback:function ($$v) {_vm.$set(_vm.data, "note", $$v)},expression:"data.note"}})],1)],1)],1)],1),_c('b-button',{staticClass:"d-block m-auto mt-2",attrs:{"disabled":_vm.data.note.length > _vm.noteLimit || invalid,"variant":"primary"},on:{"click":_vm.handleClickButton}},[_vm._v(" Visualizar Relatório ")])],1)]}}])}),(_vm.response)?_c('b-row',{staticClass:"mt-1 justify-content-center"},[_c('b-col',{attrs:{"md":"2"}},[_c('b-button',{staticStyle:{"width":"100%"},attrs:{"variant":"primary"},on:{"click":_vm.handleClickEmailButton}},[_vm._v(" Enviar E-mail ")])],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-button',{staticStyle:{"width":"100%"},attrs:{"variant":"primary"},on:{"click":_vm.downloadFile}},[_vm._v(" Download ")])],1)],1):_vm._e(),(_vm.response)?_c('div',{staticClass:"myIframe mt-1"},[_c('iframe',{attrs:{"frameBorder":"0","srcdoc":_vm.response}})]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }