<template>
  <b-container>
    <b-modal
      id="modal-report"
      size="xl"
      hide-footer
      scrollable
    >
      <b-table
        class="text-center"
        :items="history"
        :current-page="page"
        hover
        :per-page="10"
        :fields="[{
                    key: 'agent_name',
                    label: 'Representante',
                  }, {
                    key: 'date',
                    label: 'Data',
                  }, {
                    key: 'type',
                    label: 'Abatimentos e Acréscimos',
                  }, {
                    key: 'value',
                    label: 'Valor',
                  }, {
                    key: 'note',
                    label: 'Observação'
                  },
                  {
                    key: 'report',
                    label: 'Relatório'
                  },
        ]"
      >
        <template #cell(value)="item">
          {{ item.item.value | value }}
        </template>

        <template #cell(note)="row">
          <span
            v-if="row.item.note"
            v-b-tooltip.hover.top="row.item.note"
          > {{ row.item.note.length > 31 ? `${row.item.note.substr(0, 30)}...` : row.item.note }} </span>
          <span v-else> -------- </span>
        </template>
      </b-table>
      <b-pagination
        v-model="page"
        align="center"
        size="sm"
        class="mt-2"
        :per-page="10"
        :total-rows="history.length"
      />
    </b-modal>

    <b-card>
      <b-button
        variant="primary"
        class="d-block ml-auto"
        @click="getHistory"
      >
        Histórico
      </b-button>

      <validation-observer #default="{ invalid }">
        <b-form>
          <b-row>
            <b-col md="4">
              <b-form-group
                label-for="agent"
                label="Representante"
              >
                <b-form-select
                  id="agent"
                  v-model="agent"
                  :options="optionsAgents"
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label-for="month"
                label="Mês"
              >
                <b-form-select
                  id="month"
                  v-model="month"
                  :options="monthsOptions"
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label-for="year"
                label="Ano"
              >
                <b-form-select
                  id="year"
                  v-model="year"
                  :options="optionsYears"
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label-for="type"
                label="Tipo"
              >
                <b-form-select
                  id="type"
                  v-model="data.type"
                  :options="[{ value: null, text: 'Selecione um Tipo'},'Abatimento', 'Acréscimo']"
                />
              </b-form-group>
            </b-col>

            <b-col
              v-if="data.type"
              md="4"
            >
              <b-form-group
                label-for="value"
                :label="`Valor do ${data.type}`"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="`Valor do ${data.type}`"
                  rules="money"
                >
                  <b-form-input
                    id="value"
                    v-model="data.value"
                    v-money="money"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label-for="note"
                label="Observações"
                type="text-area"
              >
                <b-input-group :append="charactersLeft">
                  <b-form-input
                    id="note"
                    v-model="data.note"
                    :state="data.note.length > noteLimit ? false : null"
                    placeholder="Observações"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

          </b-row>
          <b-button
            :disabled="data.note.length > noteLimit || invalid"
            variant="primary"
            class="d-block m-auto mt-2"
            @click="handleClickButton"
          >
            Visualizar Relatório
          </b-button>
        </b-form>
      </validation-observer>

      <b-row
        v-if="response"
        class="mt-1 justify-content-center"
      >
        <b-col md="2">
          <b-button
            variant="primary"
            style="width: 100%"
            @click="handleClickEmailButton"
          >
            Enviar E-mail
          </b-button>
        </b-col>

        <b-col
          md="2"
        > <b-button
          variant="primary"
          style="width: 100%"
          @click="downloadFile"
        >
          Download
        </b-button>
        </b-col>

      </b-row>
      <div
        v-if="response"
        class="myIframe mt-1"
      >

        <iframe
          frameBorder="0"
          :srcdoc="response"
        />
      </div>
      </validation-observer>
    </b-card>
  </b-container>
</template>

<script>
import {
    BCard,
    BContainer,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BButton,
    BModal,
    BTable,
    BFormInput,
    BInputGroup,
    BPagination,
} from 'bootstrap-vue';
import { DateTime } from 'luxon';

import { ValidationObserver, ValidationProvider } from 'vee-validate';
import ComissionReportService from '@/service/comission_reports';

import '@validations';

export default {
    components: {
        BInputGroup,
        BCard,
        BContainer,
        BForm,
        BFormGroup,
        BFormSelect,
        BButton,
        BRow,
        BPagination,
        BCol,
        BFormInput,
        ValidationObserver,
        BModal,
        BTable,
        ValidationProvider,
    },

    data() {
        return {
            page: 1,
            agents: [],
            agent: {},
            month: '0',
            months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
            url: '',
            year: new Date().getFullYear(),
            response: null,
            data: { type: null, note: '' },
            noteLimit: 250,
            history: [],
        };
    },

    computed: {
        monthsOptions() {
            return this.months.map((item, index) => ({
                value: index,
                text: item,
            }));
        },
        valueWithoutSymbols() {
            return +this.data.value.replace(/[R$.]/g, '').replace(',', '.');
        },
        type() {
            return this.data.type;
        },

        charactersLeft() {
            const char = this.data.note.length;
            const limit = this.noteLimit;

            return `${char} / ${limit}`;
        },

        optionsYears() {
            const yearToday = 2017;

            const years = [];
            for (let i = yearToday; i <= new Date().getFullYear(); i += 1) {
                years.push({
                    value: i,
                    text: i,
                });
            }

            return years;
        },

        optionsAgents() {
            const agentsCannotShow = [
                'PAULO MARCELO DE CASTRO',
                'DIOGO DOURADO FAVERO',
                'LETICIA DE MELLO ANDRIGO',
            ];
            return this.agents.map(item => ({
                value: item,
                text: item.name,
            })).sort((a, b) => a.text - b.text).filter(item => !agentsCannotShow.includes(item.text.toUpperCase()));
        },
    },

    watch: {
        agent() {
            this.response = null;
        },

        month() {
            this.response = null;
        },

        year() {
            this.response = null;
        },

        type() {
            this.data.value = null;
        },
    },

    async mounted() {
        this.callLoading(true);
        await this.getAllAgents();
        this.callLoading(false);
    },

    methods: {
        getLastDate() {
            return new Date(this.year, this.month + 1, 0);
        },

        getStartDate() {
            return new Date(this.year, this.month, 1);
        },

        async getAllAgents() {
            const { data } = await ComissionReportService.getAgents();
            const [firstAgent] = data;
            this.agents = data;
            this.agent = firstAgent;
        },

        async handleClickEmailButton() {
            const isConfirmedAction = await this.confirmAnAction('Você deseja enviar o relatório por e-mail?');

            if (!isConfirmedAction) return;
            const { email } = this.agent;
            const { year, month } = this;

            const lastDate = new Date(year, month + 1, 0);

            const response = await ComissionReportService.reportComissionSend({
                email,
                date: DateTime.fromJSDate(lastDate).toFormat('yyyy-MM-dd'),
            });

            if (response.status === 200) {
                this.modalSuccess('E-mail enviado com sucesso!');
            } else {
                this.modalError('Erro ao enviar e-mail!');
            }
        },

        async downloadFile() {
            await ComissionReportService.reportDownload({
                name: this.agent.name,
            }).then(response => {
                const fileURL = window.URL.createObjectURL(new Blob([response.data]));
                const fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', `Relatório de Comissão ${this.agent.name}.pdf`);
                document.body.appendChild(fileLink);

                fileLink.click();
            });
        },

        async handleClickButton() {
            this.callLoading(true);
            const { id: agentId } = this.agent;
            const { year, month } = this;

            const startDate = this.getStartDate();
            const lastDate = this.getLastDate();

            const formatter = date => DateTime.fromJSDate(date).toFormat('yyyy-MM-dd');

            const formattedStartDate = formatter(startDate);
            const formattedEndDate = formatter(lastDate);

            const { months } = this;

            const value = (`${this.type === 'Abatimento' ? '-' : ''}${this.data.value}`).replace(/[R$ ]/g, '');

            await ComissionReportService.generatePdf(
                {
                    id: agentId,
                    dateInitial: formattedStartDate,
                    dateEnd: formattedEndDate,
                },
                {
                    ...this.data,
                    value: this.type ? value : 0,
                    date: `${months[month]}/${year}`,
                },
            );

            const response = await ComissionReportService.getReport({
                agentId,
                dateInitial: formattedStartDate,
                dateEnd: formattedEndDate,
            });

            this.callLoading(false);

            if (response.status === 204) {
                this.modalError('Não possui informações com esses paramêtros');
            } else {
                this.response = response.data;
            }
        },

        async getHistory() {
            const response = await ComissionReportService.getHistory({
                id: this.agent.id,
            });

            this.history = response.data;
            this.$bvModal.show('modal-report');
        },

        async getComissionHistory() {
            const response = await ComissionReportService.getHistoryComission({
                id: this.agent.id,
                dateEnd: this.getLastDate(),
            });

            this.history = response.data;
        },
    },
};
</script>

<style>
    .myIframe {
        position: relative;
        padding-bottom: 65.25%;
        padding-top: 30px;
        height: 0;
        overflow: auto;
        -webkit-overflow-scrolling: touch; /*<<--- THIS IS THE KEY*/
    }

    .myIframe iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 2000px !important;
        height: 100%;
    }
</style>
